@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

// Load Open Sans and satisfy typeface font
@import '@fontsource/open-sans';
@import '@fontsource/open-sans/600.css';
@import '@fontsource/open-sans/700.css';
@import '@fontsource/satisfy';
// External
@import 'react-time-picker/dist/TimePicker.css';
// Base css file
@import '../styles/scrollbar.css';
@import '../styles/swiper-carousel.css';
@import '../styles/custom-plugins.css';
@import '../styles/tailwind.css';
@import '../styles/searchInput.css';
@import 'react-phone-number-input/style.css';

@import '../styles/additional-styles/utility-patterns';
@import '../styles/additional-styles/range-slider';
@import '../styles/additional-styles/toggle-switch';
@import '../styles/additional-styles/flatpickr';
@import '../styles/additional-styles/theme';
@import '../styles/full-calendar';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

p:last-of-type {
  margin-bottom: revert;
}

.DateInput__disabled {
  visibility: hidden !important;
  display: none !important;
}

.SingleDatePicker_picker {
  top: 0px !important;
}

.DayPicker {
  box-shadow: none !important;
  border-radius: unset !important;
}

.step-primary:after {
  color: white !important;
}

input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
  height: 1.5rem !important;
}

input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute.react-time-picker__inputGroup__input--hasLeadingZero {
  height: 1.5rem !important;
}

input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
  height: 1.5rem !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.shadow-pricing {
  --tw-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0px 0px 40px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-testimonial {
  --tw-shadow: 0px 10px 20px 0px rgba(92, 115, 160, 0.07);
  --tw-shadow-colored: 0px 10px 20px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

// Swiper Material
:root {
  --swiper-material-scale: 1;
  --swiper-material-slide-size: 100%;
  --swiper-material-slide-border-radius: 32px;
}

.swiper-material-wrapper {
  border-radius: var(--swiper-material-slide-border-radius);
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  pointer-events: auto;
}
.swiper-material-content {
  position: absolute;
  transform: translate3d(0, 0, 0);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-horizontal .swiper-material-content {
  height: 100%;
  width: var(--swiper-material-slide-size);
  left: calc(50% - var(--swiper-material-slide-size) / 2);
  top: 0;
}
.swiper-vertical .swiper-material-content {
  width: 100%;
  height: var(--swiper-material-slide-size);
  top: calc(50% - var(--swiper-material-slide-size) / 2);
  left: 0;
}
.swiper-material {
  height: 60vh;
  width: calc(100% - 32px);
  max-width: 800px;
}
