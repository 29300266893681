.fc-col-header-cell {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.fc-theme-standard th {
  border: none !important;
}

.fc-col-header thead {
  border-bottom: 1px solid var(--fc-border-color);
}

.fc-col-header thead .fc-col-header-cell-cushion {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5715;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  background-color: #e5e7eb;
  border-radius: 9999px;
  color: #1f2937;
  font-weight: 500;
  font-size: 0.7rem;
  min-width: 3.5em; /* adjust this as per your needs */
  max-width: 3.5em; /* adjust this as per your needs */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #f3f4f6 !important;
}
