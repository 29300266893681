@import '~react-image-gallery/styles/css/image-gallery.css';
@import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
@import '~grapesjs/dist/css/grapes.min.css';

/* end Grapejs */
/* Drawer */
.drawer-search {
  transform: translate(-50%, 50px);
}
.drawer-search.open {
  @apply fixed block visible opacity-100 top-0;
  transform: translate(-50%, 0);
}
.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
  visibility: hidden;
  @apply w-full;
  @apply h-full;
  @apply flex;
  @apply fixed;
  @apply opacity-0;
  @apply top-0;
  @apply start-0;
  transition: 0.35s ease-in-out;
}
.overlay.open {
  @apply opacity-100;
  visibility: visible;
}

.zoom-in-cursor .image-gallery-image {
  cursor: url('data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0LjA1IiBoZWlnaHQ9IjQ0LjA1IiB2aWV3Qm94PSIwIDAgNDQuMDUgNDQuMDUiPjxwYXRoIGQ9Ik00NS4wNSwyM2EyMiwyMiwwLDEsMC0yMiwyMkEyMiwyMiwwLDAsMCw0NS4wNSwyM1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgc3R5bGU9ImZpbGw6I2ZmZiIvPjxwYXRoIGQ9Ik0yMywxNi42NVYyOS40TTI5LjQsMjNIMTYuNjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQiLz48L3N2Zz4='),
    auto;
}
