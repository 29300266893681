@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
    --sidebar-background: red;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark:root {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Write your own custom base styles here */
@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  html,
  body {
    @apply font-body text-body antialiased;
  }
  p {
    @apply mb-5;
  }
  p:last-of-type {
    @apply mb-0;
  }
  p > a {
    @apply text-body transition;
  }
  p > a:hover {
    @apply text-heading;
  }
}

@layer components {
  .menuIcon {
    width: 26px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @apply space-y-1.5;
  }
  .bar {
    width: 100%;
    height: 2px;
    transition: all 0.25s ease-in-out;
    @apply flex rounded-2xl bg-heading;
  }
  .bar:first-child {
    width: 50%;
  }
  .bar:last-child {
    width: 75%;
  }
  .menuBtn:hover .bar:first-child,
  .menuBtn:hover .bar:last-child {
    @apply w-full;
  }
  .menuBtn:hover .bar:nth-child(2) {
    width: 60%;
  }

  /* Header Menu */
  #siteHeader.is-scrolling .innerSticky {
    @apply shadow-header;
  }
  .headerMenu .menuItem > a::before {
    @apply absolute -bottom-7 end-0 w-0 bg-heading;
    content: '';
    height: 3px;
    transition: all 0.3s ease-in-out 0s;
  }

  .megaMenu,
  .subMenu {
    transition: all 0.4s;
    top: calc(100% + 25px);
  }
  .megaMenu {
    width: 970px;
    @apply opacity-0;
  }
  @media (min-width: 1280px) {
    .megaMenu {
      width: 1070px;
    }
  }
  @media (min-width: 1500px) {
    .megaMenu {
      width: 1200px;
    }
  }
  .megaMenu ul > li:last-of-type {
    @apply mb-0 border-0 pb-0;
  }
  .subMenu {
    width: 220px;
  }
  @media (min-width: 1280px) {
    .subMenu {
      width: 240px;
    }
  }
  .subMenuChild {
    transition: all 0.3s;
  }
  .subMenu li:hover > .subMenuChild {
    @apply visible -top-3 opacity-100;
  }
  .subMenu > ul > li:hover {
    @apply hover:bg-gray-300 hover:text-heading;
  }
  .megaMenu .subMenuGrid > li:last-of-type {
    @apply mb-0 border-b-0 pb-0;
  }

  .headerMenu .menuItem:hover > a::before {
    @apply end-auto start-0 w-full;
  }
  .headerMenu .menuItem > .megaMenu {
    @apply invisible;
  }
  .headerMenu .menuItem:hover > .megaMenu,
  .headerMenu .menuItem:hover > .subMenu {
    @apply visible top-full opacity-100;
  }
  /* Category Menu & Header Three */
  .categoryMegaMenu {
    @apply invisible top-6 opacity-0;
    transition: all 0.4s;
  }
  .categoryMegaMenu .megaMenu {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.08);
  }
  .categoryMegaMenu .megaMenu > .grid {
    @apply grid-cols-3;
  }
  .categoryMegaMenu .megaMenu > .grid > ul {
    @apply bg-white odd:bg-gray-150;
  }
  .subMenu li:hover > .categoryMegaMenu {
    @apply visible top-0 opacity-100;
  }
  .categoryMenu > .subMenu > ul {
    @apply py-6;
  }
  .categoryMenu > .subMenu > ul > li > a {
    @apply py-2.5;
  }
  .categoryMenu:hover > .subMenu {
    @apply visible opacity-100;
    top: calc(100% + 10px) !important;
  }
  .headerThree .headerMenu .menuItem > .megaMenu {
    @apply -start-32 xl:start-0;
  }
  .headerThree .headerMenu > .menuItem {
    @apply py-3;
  }
  .headerThree .headerMenu .menuItem > a::before {
    @apply -bottom-3;
  }

  /* Mobile Drawer Menu */
  .mobileMenu .menu-item.active {
    @apply font-semibold text-black;
  }
  .mobileMenu .menu-item.active::before {
    content: '';
    width: 3px;
    @apply absolute bottom-0 start-0 top-0 flex h-full bg-heading;
  }
  .mobileMenu li li {
    @apply text-sm text-body;
  }
  .mobileMenu li li div > a {
    @apply relative py-2.5 text-body;
  }
  .mobileMenu li > ul > li > div > a:before {
    content: '';
    height: 1px;
    @apply absolute start-1.5 top-1/2 flex w-1.5 bg-body md:start-3 md:w-2;
  }

  /* Hero Banner */
  @media (min-width: 1500px) {
    .heroBannerOne > div {
      margin-left: -500px;
      margin-right: -500px;
    }
  }
  @media (min-width: 1780px) {
    .heroBannerOne > div {
      margin-left: -750px;
      margin-right: -750px;
    }
  }
  @media (min-width: 1850px) {
    .heroBannerOne > div {
      margin-left: -850px;
      margin-right: -850px;
    }
  }

  /* Exclusive Year Section css */
  .exclusiveYear {
    opacity: 0.02;
    font-size: 8rem;
  }
  @media (min-width: 1280px) {
    .exclusiveYear {
      font-size: 160px;
    }
  }
  @media (min-width: 1500px) {
    .exclusiveYear {
      font-size: 200px;
    }
  }
  @media (min-width: 1780px) {
    .exclusiveYear {
      font-size: 240px;
    }
  }

  @media (min-width: 667px) {
    .exclusiveImage {
      min-width: 280px;
      min-height: 300px;
    }
  }

  /* Your own custom utilities */
  .productColor.active {
    @apply border-heading;
  }
  .productSize.active {
    @apply border-heading bg-heading text-white;
  }
  .productTags a:last-of-type > span {
    display: none;
  }
  /* For login and signup switch */
  .switch {
    height: 22px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #212121;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #707070;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #707070;
  }
  input::placeholder {
    color: #707070;
  }

  .popupClosed {
    @apply -end-3.5 -top-3.5 md:-end-4 md:-top-4;
  }
  .h-screen-40 {
    height: calc(100vh - 40px);
  }
  .modal-root .os-content {
    @apply flex items-center justify-center lg:block;
  }

  .cart-counter-badge {
    min-width: 20px;
    min-height: 20px;
    padding: 2px;
    border-radius: 20px;
    font-size: 10px;
    right: -0.75rem;
  }

  @media (max-wdith: 1279px) {
    .cart-counter-badge {
      min-width: 16px;
      min-height: 16px;
    }
  }
  .carouselWrapper.testimonial-carousel .swiper-horizontal > .swiper-scrollbar {
    @apply md:start-7;
  }
  .carouselWrapper .product-gallery + div {
    @apply hidden;
  }
  .carouselWrapper.pagination-left .swiper-pagination-bullets {
    @apply start-6 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-24 3xl:start-28;
  }
  .hero-slider-pagination-area
    .carouselWrapper.pagination-left
    .swiper-pagination-bullets {
    @apply start-6 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-20;
  }
  .carouselWrapper.carousel-full-width.pagination-left
    .swiper-pagination-bullets {
    @apply start-5 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-24 3xl:start-48;
  }

  /* Tab Section */
  .tab-ul {
    @apply no-scrollbar relative mb-[30px] flex h-16 flex-nowrap overflow-y-hidden overflow-x-scroll whitespace-nowrap text-center text-sm font-medium text-gray-600 before:absolute before:bottom-0 before:start-1 before:w-[200%] before:rounded-md before:border-b-[5px] before:border-[#D9D9D9];
  }
  .tab-li-selected {
    @apply active relative me-8 inline-flex h-16 rounded-t-lg border-transparent px-1 py-4 text-sm font-semibold text-gray-700 before:absolute before:bottom-0 before:h-[5px] before:w-full before:rounded-md before:bg-gray-600 sm:text-base;
  }
  .tab-li {
    @apply me-8 inline-flex h-16 cursor-pointer rounded-t-lg px-1 py-4 text-sm font-normal hover:text-gray-700 sm:text-base;
  }
}

/* Breadcrumb truncate last child */
.chawkbazarBreadcrumb li:last-child {
  @apply truncate;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    /* width: 0px;
    height: 0px;
    background: transparent; */
    width: 0;
    height: 0;
    display: none;
    -webkit-appearance: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: transparent transparent;
  }

  .no-scrollbar::-webkit-scrollbar-thumb:horizontal,
  .no-scrollbar::-webkit-scrollbar-thumb:vertical {
    background: transparent;
    display: none;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
