:root {
  --placeholder-font-size: 12px;
  --placeholder-color: #777;
}

.searchInput::placeholder {
  font-size: var(--placeholder-font-size);
  color: var(--placeholder-color);
}

.searchInput::-webkit-input-placeholder {
  /* Chrome, Opera, Safari */
  font-size: var(--placeholder-font-size);
  color: var(--placeholder-color);
}

.searchInput::-moz-placeholder {
  /* Firefox 19+ */
  font-size: var(--placeholder-font-size);
  color: var(--placeholder-color);
  opacity: 1; /* Required because Firefox reduces the opacity by default */
}

.searchInput:-ms-input-placeholder {
  /* IE 10+ */
  font-size: var(--placeholder-font-size);
  color: var(--placeholder-color);
}

.searchInput:-moz-placeholder {
  /* Firefox 18- */
  font-size: var(--placeholder-font-size);
  color: var(--placeholder-color);
  opacity: 1;
}
