/* Range slider */
:root {
    --range-thumb-size: 36px;
}

input[type=range] {
    appearance: none;
    background: #ccc;
    border-radius: 3px;
    height: 6px;
    margin-top: calc((var(--range-thumb-size) -  6px) * 0.5);
    margin-bottom: calc((var(--range-thumb-size) -  6px) * 0.5);
    --thumb-size: {--range-thumb-size};  
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: var(--range-thumb-size);
    width: var(--range-thumb-size);        
}

input[type=range]::-moz-range-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size;    
}

input[type=range]::-ms-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size;
}

input[type=range]::-moz-focus-outer {
    border: 0;
}